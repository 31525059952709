
import NavBar from "../components/NavBar";
import Summary from "../components/Summary";
import SpecializeCard from "../components/SpecializeCard";
import TestimonyCard from "../components/TestimonyCard";
import MotiveCard from "../components/MotiveCard";
import ClientCard from "../components/ClientCard";
import FooterBar from "../components/FooterBar";
import { Logo } from '../assets/imgs/logo.png';
import Icon1 from '../assets/imgs/icon1.png';
import Icon2 from '../assets/imgs/icon2.png';
import Icon3 from '../assets/imgs/icon3.png';
import Icon4 from '../assets/imgs/icon4.png';
import Person1 from '../assets/imgs/person1.png';
import Vision from '../assets/imgs/visioni.png';
import Mission from '../assets/imgs/missioni.png';
import Eye from '../assets/imgs/eye.png';
import Pad from '../assets/imgs/dartpad.png'
import LeftCorner from '../assets/imgs/corner_left.png';
import RightCorner from '../assets/imgs/cornet_right.png';
import Client1 from '../assets/imgs/client1.png';
import Client2 from '../assets/imgs/client2.png';
import Client3 from '../assets/imgs/client3.png';
import Client4 from '../assets/imgs/pdf-client-card.jpeg';
import Client5 from '../assets/imgs/client5.png';
import Banner from '../assets/imgs/meeting.png';
import Gif1 from '../assets/imgs/gif1.gif';
import Gif2 from '../assets/imgs/gif2.gif';
import Gif3 from '../assets/imgs/gif3.gif';
import Gif4 from '../assets/imgs/gif4.gif';



export default function HomePage() {
    const specializeCards = [
        { name: Icon1, heading: "web development", text: "Offering bespoke websites, including business websites and e-commerce platforms, designed to support client growth and operational efficiency" },
        { name: Icon2, heading: "Mobile Application Development", text: "Creating cutting-edge mobile applications suitable for both academic and commercial purposes" },
        { name: Icon3, heading: "Digital Products", text: "Offering a variety of digital services, including second-hand laptops and pre-built software solutions" },
        { name: Icon4, heading: "Training and Educational Support", text: "We provide extensive support for college students through project assistance,real-world development guidance." }
    ]

    const oneTestimony = {
        image: Person1,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        name: "john doe",
        job: "business owner"
    }

    const motives = [
        {
            image: Eye,
            text: "Our vision is to help businesses thrive with customized digital solutions while empowering students and youth with essential technical skills. We aim to blend education, technology, and social impact, creating a future where both businesses and individuals flourish",
            bg: Vision
        },
        {
            image: Pad,
            text: "Enable Business Success Deliver Meaningful Solutions Empower Students and Youth Promote Learning through Workshops Give Back to Society",
            bg: Mission
        }
    ]

    const clients = [
        {
            name: "kutty nadar jewellers",
            image: Client1,
            text: "kutty nadar jewellers We are developing a comprehensive ERP system with a custom inventory management and billing solution to streamline their operations in handling gold, silver, and diamond products."
        },
        {
            name: "vebas crafts",
            image: Client2,
            text: "vebas crafts We created a user-friendly website to enhance their online presence, showcasing their craftsmanship and boosting their business."
        },
        {
            name: "xpressify",
            image: Client3,
            text: "xpressify  We developed a drop-shipping e-commerce platform that specializes in selling gifts, gadgets, home decor, and kitchen essentials, designed to streamline their business operations and customer experience."
        },
        {
            name: "PDF Whisper",
            image: Client4,
            text: "PDF Whisper is a tool that processes and analyzes PDF documents using language models and embeddings to enhance data extraction and understanding."
        },
        {
            name: "Exam Management System project",
            image: Client5,
            text: "Rookiezz Solutions helped me a lot with my Exam Management System project. They made everything simple and easy to understand. Highly recommend for project support!"
        },


    ]


    return (
        <div className="homepage">

            <div style={{ display: "flex", justifyContent: "center" }}>
                <NavBar />
            </div>



            {/* TODO: need to add that images and text area with Book a call button*/}
            <div className="registernow-container" style={{ marginTop: "120px" }}>
                <button className="register-now" >Register Now</button>
            </div>
            <div className="" style={{ position: 'relative', height: "520px" }}>
                <div className="section-container box1">
                    <p className="section-text">
                        "Where Innovation Meets Execution"Delivering cutting-edge IT solutions and freelance expertise to drive your business forward
                    </p>
                    <img height="490px" src={Gif1}>
                    </img>
                </div>
                <div className="section-container box2">
                    <p className="section-text">
                        Transforming ideas into impactful results with creative solutions and specialized expertise.
                    </p>
                    <img height="400px" src={Gif2}>
                    </img>
                </div>
                <div className="section-container box3">
                    <p className="section-text">
                        Pioneering the future of business through innovative strategies and top-tier talent.
                    </p>
                    <img height="400px" src={Gif3}>
                    </img>
                </div>
                <div className="section-container box4">
                    <p className="section-text">
                        Bringing your vision to life with cutting-edge technology and professionals who drive progress.
                    </p>
                    <img height="490px" src={Gif4}>
                    </img>
                </div>
            </div>

            <div className="book-a-container" style={{ margin: "0 400px" }}>
                <button className="book-a-call">
                    <span className="btn-text-one" >
                        Book A Call
                    </span>
                    <span className="btn-text-two" >
                        Book A Call
                    </span>
                </button>
            </div>

            <button className="whatsapp-button" style={{ margin: "80px 160px" }}>WhatsAPP</button>
            <div className="gradient-section">
                {/* {this area if for that gradient effect which blends the black area to gray area} */}
            </div>
            <div className="wapper">
                <Summary />
                <h3 className="section-heading" style={{ marginTop: "60px" }}>we specialize</h3>
                <div className="specialize-container">
                    {
                        specializeCards.map(element => <SpecializeCard image={element.name} heading={element.heading} text={element.text} />)
                    }
                </div>
                <h3 className="section-heading">Our client say’s</h3>
                <TestimonyCard />
                <div className="motive-container">
                    {
                        motives.map(element => <MotiveCard image={element.image} text={element.text} bg={element.bg} />)
                    }
                </div>
                <div className="query-card">
                    <p className="query-text">Driven by innovation and a passion for excellence, we focus on creating impactful solutions that address real-world challenges. With a dedicated team of experts and a forward-thinking approach, we strive to redefine standards and make a lasting difference.We specialize in designing and developing products that address modern-day challenges, from idea to launch. Our process ensures quality, efficiency, and scalability.</p>
                    <div className="input-container">
                        <input className="query-input" type="text" placeholder="If you have any Queries?" />
                        <button className="query-button">Register Free Demo</button>
                    </div>
                    <img className="left-corner" src={LeftCorner} alt="" />
                    <img className="right-corner" src={RightCorner} alt="" />
                </div>
                <h3 className="section-heading">Key Client and Projects</h3>
                <div className="clients-container">
                    {
                        clients.map(ele => <ClientCard name={ele.name} image={ele.image} text={ele.text} />)
                    }
                </div>
                <div className="banner" style={{ backgroundImage: `url(${Banner})`, boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.6)" }}>
                    <div style={{ height: "100%" }}>
                        <h3 className="banner-title">
                            Academic and Research Projects
                        </h3>
                        <p className="banner-text">Rookiezz Solutions also collaborates with Hi Fy Technologies to support IEEE projects, research papers, and theses for students pursuing BE, ME, and MCA. We offer AI and machine learning projects, which are ideal for academic and research work.</p>
                        <p className="banner-text">We also provide customized mobile and software development projects to college students, ensuring practical learning and skill enhancement for real-world application.</p>
                    </div>

                </div>
                <FooterBar />
            </div>

        </div>
    );
}