export default function ClientCard({ name, image, text }) {
    return (
        <div className="client-card card-hover">
            <h3 className="client-name">{name}</h3>
            <div className="client-image-box">
                <img className="client-image" width="350px" height="306px" src={image} alt="" />
            </div>
            <p className="client-text">{text}</p>
        </div>
    );
}