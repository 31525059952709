import React from "react";

const MottoCard = ({ title = "", isButtonReq = false, imgSrc }) => {
  const mottoList = [
    {
      title: "Give Back to Society",
      description:
        "For every project we complete, contribute meals to the needy, integrating social responsibility with business success.",
    },
    {
      title: "Promote Learning through Workshops",
      description:
        "Conduct workshops and skill development sessions to build practical expertise among students and youth.",
    },
    {
      title: "Empower Students and Youth",
      description:
        "Offer project support, technical courses, and hands-on training to nurture future innovators.",
    },
    {
      title: "Deliver Meaningful Solutions",
      description:
        "Focus on creating impactful, reliable solutions for businesses and individuals, ensuring mutual growth and success.",
    },
    {
      title: "Enable Business Success",
      description:
        "Provide businesses with tailored web development, e-commerce solutions, branding, and digital marketing services to drive growth and transformation.",
    },
  ];

  const mottoList2 = [
    {
      title: "Give Back to Society",
      description:
        "For every project we complete, contribute meals to the needy, integrating social responsibility with business success.",
    },
    {
      title: "Client Focus",
      description:
        "We are participant-centred in our approach and recognise that every individual requirement is unique. We provide the best service available.",
    },
    {
      title: "Quality Services",
      description:
        "We take great pleasure in the quality of our care, and our team is constantly available, operating 24 hours a day, seven days a week.",
    },
    {
      title: " Agile and Innovative Approach",
      description:
        "In a fast-paced digital world, we stay ahead by embracing the latest technologies and agile methodologies.",
    },
  ];

  const data = isButtonReq ? mottoList2 : mottoList;

  return (
    <div className="motto-list-container">
      <h1 className="motto-list-title">{title}</h1>
      {data.map((item, index) => (
        <div
          key={index}
          style={index !== item.length - 1 ? { marginBottom: 24 } : {}}
          className="motto-item-container"
        >
          <div>
            <img src={imgSrc} width= {isButtonReq ? "30px" : "50px"} height={isButtonReq ? "30px" : "50px"}/>
          </div>

          <div>
            <p className="motto-head" style={{ display: "inline-block" }}>
              {item.title}:{" "}
            </p>
            <p className="motto-desc" style={{ display: "inline-block" }}>
              {item.description}
            </p>
          </div>
        </div>
      ))}
      {isButtonReq && (
        <div className="button-container">
          <button className="apply-btn">Apply</button>
        </div>
      )}
    </div>
  );
};

export default MottoCard;
