function Home1() {
    return (
      <section>
        <div className="big-container">
          <div className="top">
            <div className="one">
              <p>Get A Free Consultaions</p><br/>
              <p className="numb"><b>9894501379</b></p>

            </div>
            <div className="two">
              Our Creative Team Crafts User-Centered Designs That Enhance Usability And <br/>
              Customer Satisfaction. We Prioritize User Experience To Ensure That Every <br/>
              Interaction Is Intuitive And Engaging.

            </div>
            <div className="three">
              <button>
                Make Appointment
              </button>

            </div>

          </div>

          <div className="mid">
            <p><b>Our Process</b></p>
            <p><span>Process</span> We Follow</p>

          </div>

          <div className="bottom">
            At Rookiezz Solutions, We Thrive On Pushing Boundaries And Embracing New Ideas. Innovation Is At The Heart Of Everything We Do, Driving Us To Create Cutting-Edge <br/>
            Solutions That Meet The Evolving Needs Of Our Clients. We Believe In Building Strong Partnerships With Our Clients. By Putting Our Clients First, We Ensure That Every Project is<br/>
            Aligned With Their Goals, Delivering Value That Drives Long-Term Success.Quality Is Our Cornerstone: Our Team Is Dedicated To Delivering Solutions That Exceed<br/>
             Expectations, Paying Meticulous Attention To Detail And Employing Best Practices To Ensure Excellence In Every Product Rookiezz Solutions Is A Company Built By Passionate<br/>
              Individuals With A Relentless Drive For Growth. We Are Continuously Learning And Evolving, Both As individuals And As A Team, To Stay Ahead in A Dynamic industry.

          </div>

        </div>

          
      </section>
    );
  }
  
  export default Home1;
  