import React from "react";
import NavBar from "../components/NavBar";
import MottoCard from "../components/mottoCard";
import People1 from "../assets/imgs/aboutPeople.png";
import People2 from "../assets/imgs/people-2.png";
import FooterBar from "../components/FooterBar";
import Gif1 from "../assets/imgs/goal.gif";
import BlueArrow from "../assets/imgs/arrowBlueIcon.png";
import Graph from "../assets/imgs/graph.gif";

const AboutPage = () => {
  return (
    <div style={{ backgroundColor: "#D9D9D9" }}>
      <nav style={{ display: "flex", justifyContent: "center",paddingTop:'2rem' }}>
        <NavBar />
      </nav>  
      <div className="about-mainContainer">
        <div className=".about-content" style={{ flex: 0.6 }}>
          <div className="motto-sub-1">
            <div>About us</div>
            <div className="motto-line-header">
              <img src={Graph} height="100px" width="100px" />
              <div className="motto-line-div">
                <h1 className="motto-line line-1">Motto</h1>
                <h1 className="motto-line line-2">"Ignite the Growth"</h1>
              </div>
            </div>
          </div>
          <div className="motto-description">
            To help businesses thrive with customized digital solutions while
            empowering students and youth with technical skills. We aspire to
            blend education, technology, and social impact, creating a future
            where both businesses and individuals flourish."Founded in 2024,
            Rookiezz Solutions began with a vision to simplify business management
            through powerful and intuitive ERP systems. Over the years, we have
            grown into a dedicated team of certified Odoo experts who are
            passionate about helping businesses succeed. With hundreds of
            successful implementations and a growing list of satisfied clients,
            we pride ourselves on our deep knowledge of Odoo and commitment to
            quality.At Rookiezz Solutions, we specialize in delivering comprehensive
            Odoo ERP solutions tailored to meet the unique needs of businesses
            across various industries. Our mission is to empower organizations
            by optimizing their operations, improving productivity, and driving
            growth through innovative and customized Odoo applications.
          </div>
        </div>
        <div className="about-goalContainer">
          <div>
            <MottoCard imgSrc={Gif1} />
          </div>
        </div>
      </div>

      {/* second section */}
      <section className="about-section-2">
        <div className="section2-img">
          <div></div>
          <img src={People1} height="250px" width="200px" />
        </div>
        <div className="section2-desc-container">
          <p className="section2-head">
            "In the world of business, those who dream big set the path for the
            rest to follow."
          </p>
          <p className="section2-desc">
            Our team consists of highly skilled freelance developers, designers,
            and project managers who are experts in their respective fields. We
            bring the flexibility and dedication of freelancers with the
            efficiency and professionalism of an established firm.
          </p>
        </div>
      </section>

      {/* section - 3 */}
      <section className="about-section-3">
        <div className="section3-img">
          <img src={People2} width="350px" height="350px" />
        </div>
        <div style={{ flex: 0.5, justifyContent: "flex-end", margin: "64px" }}>
          <MottoCard
            title={"Why Choose Rookiezz Solutions?"}
            isButtonReq={true}
            imgSrc={BlueArrow}
          />
        </div>
      </section>

      {/* Footer */}
      <FooterBar />
    </div>
  );
};

export default AboutPage;
