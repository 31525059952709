


export default function SpecializeCard({ image, heading, text }) {
    return (
        <div className="specialize-card card-hover">
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className="specialize-img">
                    <img height="40px" src={image}></img>
                </div>

            </div>
            <h4 className="specialize-heading">{heading}</h4>
            <p className="specialize-text">{text}</p>
        </div>
    );
}