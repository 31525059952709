import Circle from '../assets/imgs/rookies-circle.png';
import Insta from '../assets/imgs/instagram.png';
import Fb from '../assets/imgs/facebook.png';
import LinkedIn from '../assets/imgs/linkedin.png';
import X from '../assets/imgs/x.png';
import Phone from '../assets/imgs/call.png';
import Mail from '../assets/imgs/mail.png';
import Location from '../assets/imgs/locationpin.png';


export default function FooterBar() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="useful-links">
                    <div className="section-o">
                        <img src={Circle} alt="" />
                        <div className="quick-links-container">
                            <p className="f-heading">Quick Links</p>
                            <div className="section-o-line"></div>
                            <ul className="list">
                                <li className="list-item">About Us</li>
                                <li className="list-item">Services</li>
                                <li className="list-item">Contact Us</li>
                                <li className="list-item">Blogs</li>
                            </ul>
                        </div>
                    </div>
                    <div className="section-t">
                        <div className="keepintouch-container">
                            <p className="f-heading">Keep in Touch</p>
                            <div className="section-t-line"></div>
                            <ul className="list">
                                <li className="list-item"><img style={{ margin: '0 7px' }} src={Phone} alt="" />9894501379</li>
                                <li className="list-item"><img style={{ margin: '0 7px' }} src={Mail} alt="" />rookiezzsolutions@gmail.com</li>
                                <li className="list-item"><img style={{ margin: '0 7px' }} src={Location} alt="" />Sri Sai Avenue Annexue, Navalur,Chennai.</li>
                            </ul>
                        </div>
                        <div className="followus-container">
                            <div className="followus">
                                <p className='f-heading' style={{ textAlign: 'center' }} >Follow Us</p>
                                <ul className="social-links">
                                    <li className="social-item">
                                        <a href='https://www.instagram.com/rookiezz_solutions?igsh=MTkzMHJsbWF2eHNxcQ==' target='_blank'><img src={Insta} alt="" ></img></a>
                                    </li>
                                    <li className="social-item">
                                        <a href='https://www.facebook.com/profile.php?id=61567927510136&mibextid=ZbWKwL' target='_blank'><img src={Fb} alt="" ></img></a>
                                    </li>
                                    <li className="social-item">
                                        <a href='https://www.linkedin.com/company/rookiezz-solutions/' target='_blank'><img src={LinkedIn} alt="" ></img></a>
                                    </li>
                                    <li className="social-item">
                                        <a><img src={X} alt="" ></img></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="serperator-line"></div>
                <div className="bottom-data">
                    <p className='little-text'>copyrights@2024</p>
                    <p className='little-text'>All Rights Reserved-Rookiezz Solutions</p>
                </div>
            </div>
        </footer>
    );
}