
import Meet from '../assets/imgs/meeting.png'

export default function MotiveCard({ image, text, bg }) {
    return (
        <div style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: "cover", boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.6)" }} className="motive-card " >
            <div className='' style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                <div className='symbol-image'
                    style={{ backgroundColor: "#464646", border: "3px solid black", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img className="" height="60px" width="60px" src={image}></img>
                </div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <p className='motive-text'>{text}</p>
            </div>




        </div>
    );
}