import react from 'react';
import Logo from '../assets/imgs/logo.png';
import { Link } from "react-router-dom";

export default function NavBar() {

    // NOTE: Belive me navbar is working you can use it , there is only some css erros and leftout contents
    return (
        <header className="nav-bar">

            <a href="" alt="company logo">
                <img src={Logo} height="60px" />
            </a>
            <ul className="nav-menu">
                <li className="nav-items-li">
                    <Link className='nav-items' to="/" style={{ color: 'white', textDecoration: "none" }}>Home</Link>
                </li>
                <li className="nav-items-li">
                    <Link className="nav-items" to="/about" style={{ color: 'white', textDecoration: "none" }}>About Us</Link>
                </li>
                <li className="nav-items-li">
                    <Link className="nav-items" to="/service" style={{ color: 'white', textDecoration: "none" }}>Services</Link>
                </li>
                {/* <li className="nav-items-li">
                    <Link className="nav-items" to="" style={{ color: 'white', textDecoration: "none" }}>Blogs</Link>
                </li> */}
                <li className="nav-items-li">
                    <Link className="nav-items" to="/contactUs" style={{ color: 'white', textDecoration: "none" }}>Contact us</Link>
                </li>
            </ul>
        </header>
    );
}
