import React, { useState } from "react";
import NavBar from "../components/NavBar";
import FooterBar from "../components/FooterBar";
import mailIcon from "../assets/imgs/mail-icon.png";
import callIcon from "../assets/imgs/phone-call-icon.png";
import map from "../assets/imgs/gmap.png";
import instaIcon from "../assets/imgs/con-insta.png";
import fbIcon from "../assets/imgs/con-fb.png";
import xIcon from "../assets/imgs/con-x.png";
import LinkedInIcon from "../assets/imgs/con-linkedin.png";
import arrowUpIcon from "../assets/imgs/arrow-up.png";
import arrowDownIcon from "../assets/imgs/arrow-down.png";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    projectType: "",
    message: "",
  });

  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        contactNumber: formData.contactNumber,
        projectType: formData.projectType,
        message: formData.message,
      };
    
      try {
        const response = await fetch("https://www.rookiezzsolutions.com/contact/contactForm", {
          method: "POST",
          headers: {
            "Content-Type": "application/json", 
          },
          body: JSON.stringify(requestData),
        });
    
        if (response.ok) {
          const result = await response.json();
          console.log("Form submitted successfully:", result);
        } else {
          console.error("Error submitting form:", response.status);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    console.log(formData);
  };

  const contactForm = (
    <div className="contact-form-container">
      <p className="contact-form-title">Get In Touch</p>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="name-group">
          <div className="form-group">
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First Name*"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Last Name*"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="E-mail Id*"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <input
            type="tel"
            id="contactNumber"
            name="contactNumber"
            placeholder="Contact Number*"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <input
            id="projectType"
            name="projectType"
            placeholder="Type of projects"
            value={formData.projectType}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <textarea
            id="message"
            name="message"
            placeholder="Your message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group form-group-btn">
          <button type="submit" className="submit-btn">
            Submit{" "}
          </button>
        </div>
      </form>
    </div>
  );

  const handleFAQClick = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  const faqs = [
    {
      question: "What services does Rookiezz Solutions offer?",
      answer:
        "We specialize in software and web development, ERP and CRM solutions, mobile app development, AI integration, e-commerce platforms, graphic design, and programming training with 24/7 support.",
    },
    {
      question: "Who can benefit from your services?",
      answer:
        "Our services cater to businesses looking for tailored software solutions, as well as students and aspiring developers seeking to improve their technical skills through practical courses and project support.",
    },
    {
      question: "How does Rookiezz Solutions help businesses?",
      answer:
        "We provide businesses with customized digital solutions such as website development, ERP and CRM systems, e-commerce support, and branding, helping them grow and optimize their operations effectively.",
    },
    {
      question: "How can I get in touch with Rookiezz Solutions?",
      answer:
        "You can contact us via phone at 9894501379, email us at sales@rookiezzsolutions.com, or reach out through our website for inquiries, support, or to get started with a new project.",
    },
  ];

  return (
    <div className="contact-main-container">
      <nav style={{ display: "flex", justifyContent: "center" }}>
        <NavBar />
      </nav>

      <div className="registernow-container" style={{ marginTop: "120px" }}>
        <button className="register-now">Register Now</button>
      </div>
      {/* section 1  */}
      <section className="contact-section-1">
        <div className="contact-subSection-1">
          <p className="contactUs-text">Contact Us</p>
          <h1 className="contactUs-title">
            get in touch: we’re here to answer your questions
          </h1>

          <div className="icon-message-cont">
            <div className="info mail-cont">
              <div style={{display:'flex', alignItems:'center',paddingLeft:'5px'}}>
                <img src={mailIcon} width="20px" height="20px" />
              </div>
              <p
                style={{
                  color: "white",
                  fontSize: "10px",
                  textAlign: "center",
                }}
              >
                rookiezzsolutions@gmail.com
              </p>
            </div>
            <div className="info phone-cont">
              <div style={{display:'flex', alignItems:'center',paddingLeft:'5px'}}>
                <img src={callIcon} width="20px" height="20px" />
              </div>
              <p
                style={{
                  color: "white",
                  fontSize: "10px",
                  textAlign: "center",
                }}
              >
                +91-9894501379
              </p>
            </div>
          </div>

          <div className="desc-container">
            <div className="desc-sub-container">
              <p className="contact-desc desc-title">Customer support</p>
              <p className="contact-desc desc-description">
                our support team is available around the clock to help you with
                any queries
              </p>
            </div>

            <div className="desc-sub-container">
              <p className="contact-desc desc-title">
                feedback and suggestions
              </p>
              <p className="contact-desc desc-description">
                we value your important feedbacks and are continuously working
                towards improving ourselves to help you better.
              </p>
            </div>
          </div>
        </div>
        <div className="contact-card-hover contact-subSection-2">{contactForm} </div>
      </section>
      {/* section 2 */}
      <section className="section-2">
        <div className="sec-2-sub sec-2-sub-1">
          <img src={map} width="400px" height="500px" />
        </div>
        <div className="contact-card-hover sec-2-sub sec-2-sub-2">
          <div>
            <p>Our Locations</p>
            <p style={{ fontSize: "22px", margin: "1rem 0" }}>
              You can find us at
            </p>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            
            <p>Headquarters</p>
            <div style={{ fontSize: "14px" }}>
              Sri Sai Avenue Annexue
              <br />
              navalur
              <br />
              Chennai, 120112, india
              <br />
            </div>
            <p style={{ margin: "1.5rem 0" }}>+91-9894501379</p>
            <p style={{ fontSize: "18px" }}>Our socials</p>
            <p style={{ fontSize: "22px" }}>You Can Follow Us </p>
            <div className="icon-map-cont">
              <div className="card-hover" style={{border:'1px solid white',borderRadius:'5px'}}><img src={instaIcon} width="30px" height="30px" /></div>
              <img src={fbIcon} width="30px" height="30px" />
              <img src={xIcon} width="30px" height="30px" />
              <img src={LinkedInIcon} width="30px" height="30px" />
            </div>
          </div>

          <div>
            <p></p>
          </div>
        </div>
      </section>

      <section className="faq-section">
        {/* FAQ section */}
        <p className="faq-title">
          Got questions? Here’s what others are asking:
        </p>
        {faqs.map((faq, index) => (
          <div key={index} className=" faq-item">
            <button
              className="contact-card-hover faq-question"
              onClick={() => handleFAQClick(index)}
            >
              {faq.question}
              <span className="arrow">
                {activeQuestion === index ? (
                  <img src={arrowDownIcon} width="20px" height="20px" />
                ) : (
                  <img src={arrowUpIcon} width="20px" height="20px" />
                )}
              </span>
            </button>
            {activeQuestion === index && (
              <div className="contact-card-hover faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </section>

      <FooterBar />
    </div>
  );
};

export default ContactPage;
