


export default function Summary() {
    return (
        <div className="summary-card-container">
            <div className="summary-card card-hover">
                <h3 className="section-heading">What Makes us Different?</h3>
                <p className="summary-text">
                    <b className="summary-bold">
                        Rookiezz Solutions
                    </b>
                    is a dynamic freelancing startup and IT company founded by Ram Kishore. We specialize in providing innovative solutions across various domains, including software development, web services, e-commerce support, digital services, and programming training. Our goal is to deliver customized and impactful solutions that meet the technological and operational needs of both businesses and individuals.We bring together a handpicked team of top-tier freelancers from various fields, ensuring only the best professionals work on your projects.We don’t believe in one-size-fits-all. Our solutions are specifically designed to meet the unique needs of your business.Being a freelancing-driven company, we adapt quickly to the changing requirements of your project and provide scalable solutions.With a focus on cutting-edge technologies, we stay ahead of industry trends to offer creative solutions for your business challenges.
                </p>
            </div>
        </div>
    );
}