import { useState } from 'react';

import ArrowLeft from '../assets/imgs/arrow_left.png';
import ArrowRight from '../assets/imgs/arrow_right.png';
import Person1 from '../assets/imgs/kutty.jpeg';
import Person2 from '../assets/imgs/craft.jpeg';
import Person3 from '../assets/imgs/xpress.jpeg';
import Person4 from '../assets/imgs/karthi.jpeg';
import Person5 from '../assets/imgs/user-prof.png';


export default function TestimonyCard() {

    const testimonies = [
        {
            image: Person1,
            text: "Rookiezz Solutions optimized our inventory and billing with their custom ERP software, meeting our needs for managing gold, silver, and diamond. Their support greatly improved our efficiency.",
            name: "Kamal M.L, KUTTY NADAR JEWELLERY",
            job: "Business Owner"
        },
        {
            image: Person2,
            text: "With Rookiezz Solutions, VebasCrafts gained a well-designed website that beautifully showcases our products and improves user experience.",
            name: "VEBAS CRAFTS",
            job: "Business Owner"
        },
        {
            image: Person3,
            text: "Xpressify’s e-commerce operations were streamlined, enhancing order management and customer experience, thanks to Rookiezz Solutions.",
            name: "XPRESSIFY",
            job: "Business Owner"
        },
        {
            image: Person4,
            text: "Rookiezz Solutions helped me a lot with my Exam Management System project. They made everything simple and easy to understand. Highly recommend for project support!",
            name: "Exam Management System project",
            job: "Student"
        },
        {
            image: Person5,
            text: "They helped me with my college project, PDF Whisper, and made the process really easy. Highly recommend their support!",
            name: "PDF Whisper",
            job: "Student"
        },


    ]

    const [testimonyState, setTestimonyState] = useState(0);

    function increment() {

        setTestimonyState(
            (val) => {
                return val >= (testimonies.length - 1) ? 0 : val + 1;
            }
        )

    }

    function decrement() {
        setTestimonyState(
            (val) => {
                return val <= 0 ? (testimonies.length - 1) : val - 1;
            }
        )
    }


    return (
        <div className="testimony-card-container">
            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                <img className="person-image" src={testimonies[testimonyState].image}></img>
            </div>

            <p className='testimony-text'>{testimonies[testimonyState].text}</p>
            <p className='testimony-name'>{testimonies[testimonyState].name}</p>
            <p className='testimony-job'>{testimonies[testimonyState].job}</p>
            <div className="carousel-button-container">
                <button className='carousel-button' onClick={() => decrement()} ><img src={ArrowLeft}></img></button>
                <button className='carousel-button' onClick={() => increment()} ><img src={ArrowRight}></img></button>
            </div>
        </div>
    );
}