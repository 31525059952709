import './Home.css';

const Home2 = () => {
    return (
        <div className="container">
            <div className="wave-bg">
                {/* SVG for the custom wave path */}
                <svg viewBox="0 0 1200 400" preserveAspectRatio="none" className="wave-svg">
                    <path
                        d="M0,250 
               C150,300 150,100 300,150 
               S450,200 600,150 
               S750,100 900,150 
               S1050,300 1200,200"
                        fill="none"
                        stroke="black"
                        strokeWidth="3"
                    />
                </svg>
            </div>


            <div className="circle-item">
                <div className="circle">
                    <div className="text">Define The Project<br />
                        <span className="description">
                            Defining a project involves establishing a clear understanding of its purpose, scope, objectives, and key elements needed for success.
                        </span>
                    </div>
                    <div className="small-circle">1</div>
                </div>
            </div>

            <div className="circle-item">
                <div className="circle">
                    <div className="text">Evaluate Plans<br />
                        <span className="description">
                            Evaluating project plans is essential to ensure they are feasible, aligned with objectives, and likely to succeed.
                        </span>
                    </div>
                    <div className="small-circle">2</div>
                </div>
            </div>

            <div className="circle-item">
                <div className="circle">
                    <div className="text">Best Results<br />
                        <span className="description">
                            Achieving the best results in a project involves focusing on several key practices that optimize performance, efficiency, and quality.
                        </span>
                    </div>
                    <div className="small-circle">3</div>
                </div>
            </div>
        </div>


    );
};

export default Home2;
