import './Home.css';
import imgup from '../../assets/Service/imgup.png';
import imgdown from '../../assets/Service/imgdown.png';
import seo from '../../assets/Service/SEO.png';
import smm from '../../assets/Service/SOCIALMEDIA.png';
import graphic from '../../assets/Service/GRAPH.png';
import ecom from '../../assets/Service/E-COM.png';
import epa from '../../assets/Service/zondicons_education.png'
import progc from '../../assets/Service/progc.png';
import erpcrm from '../../assets/Service/Vector.png';

const Home = () => {
    return (
        <section className='home' id='home'>
            <div className="grid-container">
                <div className="grid-item images">
                    <img src={imgup} alt="Description 1" className="grid-image1" />
                    <img src={imgdown} alt="Description 1" className="grid-image1" />
                    <div className='ppp'>
                        <p className="image-text1">Web </p><br />
                        <p className="image-text1">& </p><br />
                        <p className="image-text1">Re Web Building </p><br />
                    </div>

                </div>
                <div className="grid-item">
                    <img src={seo} alt="Description 2" className="grid-image2" />
                    <div className='ppp'>
                        <p className="image-text">SEO</p><br />
                        <p className="image-text">(Search Engine Optimization) </p>

                    </div>

                </div>
                <div className="grid-item">
                    <img src={smm} alt="Description 3" className="grid-image3" />
                    <div className='ppp'>
                        <p className="image-text2">SOCIAL MEDIA MANAGEMENT</p>

                    </div>
                </div>
                <div className="grid-item">
                    <img src={graphic} alt="Description 4" className="grid-image3" />
                    <div className='ppp'>

                        <p className="image-text2">GRAPHICS DESIGN</p>

                    </div>
                </div>
                <div className="grid">
                    <img src={ecom} alt="Description 5" className="grid-image3" />
                    <div className='ppp'>
                        <p className="image-text2"> E-Commerce solution</p>


                    </div>
                </div>
                <div className="grid">
                    <img src={epa} alt="Description 6" className="grid-image3" />
                    <div className='ppp'>
                        <p className="image-text2"> EDUCATION PROJECT </p><br />
                        <p className="image-text2"> ASSISTANCE </p>


                    </div>
                </div>
                <div className="grid">
                    <img src={progc} alt="Description 7" className="grid-image3" />
                    <div className='ppp'>
                        <p className="image-text">Programming Courses</p>

                    </div>
                </div>
                <div className="grid">
                    <img src={erpcrm} alt="Description 8" className="grid-image2" />
                    <div className='ppp'>
                        <p className="image-text">ERP </p>
                        <p className="image-text">& </p>
                        <p className="image-text">CRM </p>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Home;
