import Home from '../components/Home/Home';
import Home1 from '../components/Home/Home1';
import Home2 from '../components/Home/Home2';
import NavBar from '../components/NavBar';
import FooterBar from '../components/FooterBar';
import bg from '../assets/imgs/service-bg.png'


function ServicePage() {

    return (
        <div className='grayscale' style={{ backgroundImage: `url(${bg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', padding: "50px 0 0 0", backgroundColor: "#D9D9D9", backgroundBlendMode: "luminosity" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <NavBar />
            </div>
            <h1 className='Service-heading'>Services</h1>
            <div className="gradient-section-s">
                {/* {this area if for that gradient effect which blends the black area to gray area} */}
            </div>
            <Home />
            <Home1 />
            <Home2 />
            <FooterBar />
        </div>
    )
}

export default ServicePage
