import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/aboutPage";
import ContactPage from "../pages/contactPage";
import ServicePage from "../pages/ServicePage";

const RouteComponent = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contactUs" element={<ContactPage />} />
                <Route path="/service" element={<ServicePage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default RouteComponent;
